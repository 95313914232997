<template>
  <div class="pageContol listWrap templateList promoteBox">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">优秀企业推荐</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-select size="small" v-model="retrievalData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" class="width_w">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addAndEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100" />
              <el-table-column label="企业名称" align="left" prop="compName" show-overflow-tooltip />
              <el-table-column label="所属城市" align="left" prop="areaNamePath" show-overflow-tooltip />
              <el-table-column label="评优日期" align="left" min-width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否已开展实习" align="left" prop="isInternship" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.isInternship ? '是' : '否' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="addAndEdit(scope.row)">编辑</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px;" @click="setEnable(scope.row)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder_excellentEnterpriserEcommend",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        compId: "", // 企业名称
      },
      // 企业名称 - 下拉数据
      CompanyList: [],
    };
  },
  computed: {},
  created() { },
  methods: {
    // 获取 - 企业名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.doFetch({
        url: "/biz/company/recommend/pageList",
        params,
        pageNum
      });
    },
    // 新增&编辑
    addAndEdit(row) {
      this.$router.push({
        path: "/web/workOrder/excellentEnterpriserEcommendInfo",
        query: {
          recommendId: row?.recommendId ?? ""
        }
      });
    },
    // 删除
    setEnable(row) {
      this.$confirm(`确认删除该企业么?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: "bgc-bv",
      }).then(() => {
        this.$post("/biz/company/recommend/delete", {
          recommendId: row.recommendId,
        }).then(() => {
          this.getData(-1);
          this.$message.success("操作成功");
        });
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if(from.path == '/web/workOrder/excellentEnterpriserEcommendInfo' && to.query.type == 2){
        vm.getData(vm.pageNum)
      }
      if(from.path == '/web/workOrder/excellentEnterpriserEcommendInfo' && to.query.type == 1){
        console.log(1)
        vm.getData(1)
      }
    })
  },
};
</script>
<style lang="less" scoped></style>
